/**
 * 函数防抖封装
 * @param {*} fn 回调函数
 * @param {*} wait  时间
 */
 export const makeDebounce = (fn, wait = 500) => {
    let timer;
    return function (...args) {
      // console.log(args);
      // console.log(("fn"), fn)
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        // console.log("fn", fn)
        fn.apply(this, args);
      }, wait);
    };
};